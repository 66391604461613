import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import * as serviceWorker from './serviceWorker';
import {fetchConfig} from "./appConfig";
import {initAuth} from "@platform/platform-auth";
import {shouldDisplay2022Nav} from '@platform/cj-platform-navigation'

const App = React.lazy(() => import('./App'))
const OldApp = React.lazy(() => import('./OldNav/OldApp'))

const init = async () => {
    const appConfig = await fetchConfig()

    ReactDOM.render(
        <React.Suspense fallback={<></>}>
            <App appConfig={appConfig} />
        </React.Suspense>,
        document.getElementById('root'));
}

const oldAppInit = async () => {
    const appConfig = await fetchConfig()

    await initAuth({
        clientId: appConfig.authenticationService.clientId,
        redirectUri: appConfig.authenticationService.redirectUri,
    })

    ReactDOM.render(
        <React.Suspense fallback={<></>}>
            <OldApp appConfig={appConfig} />
        </React.Suspense>,
        document.getElementById('root'));
}

shouldDisplay2022Nav()
    .then((shouldUseNewNav) => {
        if (shouldUseNewNav) {
            return init()
        } else {
            return oldAppInit()
        }
    })
    .catch(console.error)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
